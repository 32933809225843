import { BrowserModule, BrowserTransferStateModule, HammerModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { CoreModule } from '@core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from '@shared/shared.module';
import { HeaderModule } from '@features/header/header.module';
import { MobileMenuModule } from '@features/mobile-menu/mobile-menu.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CustomReuseStrategy, routing } from './app.routes';
import { PagesModule } from '@features/pages/pages.module';
import { FooterModule } from '@features/footer/footer.module';
import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import { SettingsHeaderInterceptor } from '@shared/http-interceptors/settings-headers.interceptor';
import { FilterModule } from '@features/filter/filter.module';
import { RouteReuseStrategy } from '@angular/router';
import { DialogModule } from '@features/dialog/dialog.module';
import { AuthenticationModule } from '@features/authentication/authentication.module';
import { OverlayModule } from '@impactdk/ngx-overlay';
import { RightpaneModule } from '@features/rightpane/rightpane.module';
import { TestComponent } from '@features/test/test.component';
import { LeftpaneModule } from '@features/leftpane/leftpane.module';
import { AuthenticationHeaderInterceptor } from '@shared/http-interceptors/authentication-headers.interceptor';
import { CustomerModule } from '@features/customer/customer.module';

import { SignupModule } from '@features/signup/signup.module';
import { ObserverService } from '@features/ngx-lazyload/observer.service';
import { ScrollToTopComponent } from '@features/scroll-to-top/scroll-to-top.component';
import { FindStoreModule } from '@features/find-store/find-store.module';
import { ProductPageModule } from '@features/product-page/product-page.module';
import { FavoritesModule } from '@features/wishlist/wishlist.module';
import { NgxCaptchaModule } from 'ngx-captcha';
import { InfoModule } from '@features/info/info.module';

import localeDa from '@angular/common/locales/da';
import localeDe from '@angular/common/locales/de';
import localeSv from '@angular/common/locales/sv';
import { NgxAnimateInModule } from '@impactdk/ngx-animate-in';
import { CookieModule } from 'ngx-cookie';
import { BUILDNUMBER, CLOUDFLAREURL } from './tokens';
import { NgxLazyloadModule } from '@features/ngx-lazyload/ngx-lazyload.module';
import { NgxSliderModule } from '@angular-slider/ngx-slider';

const buildNumber = '1.38.11-release.deploy39';
const cloudFlareUrl = 'https://www.kaufmannstatic.com/';

registerLocaleData(localeDa, 'da');
registerLocaleData(localeDe, 'de');
registerLocaleData(localeSv, 'sv');

@NgModule({
    declarations: [AppComponent, TestComponent, ScrollToTopComponent],
    imports: [
        BrowserModule.withServerTransition({ appId: 'kaufmann-app' }),
        BrowserTransferStateModule,
        HttpClientModule,
        CoreModule,
        SharedModule,
        BrowserAnimationsModule,
        PagesModule,
        MobileMenuModule,
        HeaderModule,
        FooterModule,
        routing,
        FilterModule,
        DialogModule,
        InfoModule,
        AuthenticationModule,
        HammerModule,
        FavoritesModule,
        OverlayModule,
        RightpaneModule,
        LeftpaneModule,
        CustomerModule,
        SignupModule,
        ProductPageModule,
        FindStoreModule,
        NgxCaptchaModule,
        NgxLazyloadModule.forRoot(),
        NgxAnimateInModule.forRoot({
            threshold: 0,
            rootMargin: '20%',
        }),
        CookieModule.forRoot(),
        NgxSliderModule
    ],
    entryComponents: [TestComponent],
    providers: [
        ObserverService,
        { provide: APP_BASE_HREF, useValue: '/' },
        { provide: BUILDNUMBER, useValue: buildNumber },
        { provide: CLOUDFLAREURL, useValue: cloudFlareUrl },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SettingsHeaderInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthenticationHeaderInterceptor,
            multi: true,
        },
        {
            provide: RouteReuseStrategy,
            useClass: CustomReuseStrategy,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
